@import "solar/Tokens.scss";
$padding-x: $sol-space-xs + 7px;

.title {
  margin-bottom: $sol-space-xs;
}

.label {
  margin-top: $sol-space-lg;
  margin-bottom: $sol-space-sm;
}

.phone {
  margin-top: $sol-space-xs;
  padding-right: $padding-x;
  padding-left: $padding-x;
  border: solid 1px $sol-color-gray !important;
  background: $sol-color-white;
  font-weight: 300;
}

.email {
  margin-top: $sol-space-xs;
}

.expect {
  max-width: 224px;
  margin-top: $sol-space-xs;
  margin-right: auto;
  margin-left: auto;
  font-style: italic;
  font-size: $sol-text-3;
  line-height: $sol-text-line-sm;
  text-align: center;
}

.action {
  font-weight: 500;
}
